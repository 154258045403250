

















































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
    });

    const model = reactive({
      order: 0,
      name: "",
      englishName: "",
      status: false,
      additionalInformation: "",
      englishAdditionalInformation: "",
    });

    //  Start fetch single fee group data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`registration-fee/group/${root.$route.params.rfgid}`)
        .then(({ data: { registrationFeeGroup } }) => {
          model.order = registrationFeeGroup.order;
          model.name = registrationFeeGroup.name;
          model.englishName = registrationFeeGroup.englishName;
          model.status = registrationFeeGroup.state;
          model.additionalInformation =
            registrationFeeGroup.additionalInformation;
          model.englishAdditionalInformation =
            registrationFeeGroup.englishAdditionalInformation;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single fee group data

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        order: model.order,
        name: model.name,
        englishName: model.englishName,
        state: model.status,
        additionalInformation: model.additionalInformation || null,
        englishAdditionalInformation:
          model.englishAdditionalInformation || null,
      };

      state.loading = true;

      axiosInstance
        .put(`/registration-fee/group/${root.$route.params.rfgid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
    };
  },
});
